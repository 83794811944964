import { NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { TbGeneralMaintenanceAction, TbGeneralMaintenanceProps } from '../enums/tb-general-maintenance.enum';
import { ColumnSize, FieldType, TableField, TemplateType } from '@components/organisms/next-table/next-table.type';
import {GenericTableColumnProps, NextFilterType, TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { TaskBoardConstant } from './task-board.constant';
import { FilterToggleProps } from '@components/organisms/next-table-filter/enums/next-table.filter.enum';
import { TaskBoardStatus } from '../enums/task-board.enum';
import { ColorType } from 'app/shared/enums/color.enum';
import { FilterToggleControlConfig } from '@components/organisms/next-table-filter/types/next-table-filter.type';
import { GenericObject } from '@utils/models/Types';
  
export class TbGeneralMaintenanceConstant {
    static readonly tableListConfig: TableField[] = [
        {
          [TableFieldProps.FIELD]: TbGeneralMaintenanceProps.CREATED_TIMESTAMP,
          [TableFieldProps.HEADER]: 'TASK_BOARD.GENERAL_MAINTENANCE.REQUEST_CREATION_DATE',
          [TableFieldProps.IS_FILTER]: true,
          [TableFieldProps.FIELD_TYPE]: [FieldType.DATE],
        },
        {
          [TableFieldProps.FIELD]: TbGeneralMaintenanceProps.AFFECTED_RESOURCE_NAME,
          [TableFieldProps.HEADER]: 'TASK_BOARD.GENERAL_MAINTENANCE.COUNTRY'
        },
        {
          [TableFieldProps.FIELD]: TbGeneralMaintenanceProps.LATEST_EXECUTED_STEP,
          [TableFieldProps.HEADER]: 'TASK_BOARD.GENERAL_MAINTENANCE.REQUEST_TYPE',
          [TableFieldProps.IS_FILTER]: true
        },
        {
          [TableFieldProps.FIELD]: TbGeneralMaintenanceProps.INITIATOR_CDSID,
          [TableFieldProps.HEADER]: 'TASK_BOARD.GENERAL_MAINTENANCE.REQUESTOR'
        },
        {
          [TableFieldProps.FIELD]: TbGeneralMaintenanceProps.OWNER_CDSID,
          [TableFieldProps.HEADER]: 'TASK_BOARD.GENERAL_MAINTENANCE.LAST_APPROVER',
          [TableFieldProps.FILTER_TYPE]: NextFilterType.DATE
        },
        {
          [TableFieldProps.FIELD]: TbGeneralMaintenanceProps.STATUS,
          [TableFieldProps.HEADER]: 'TASK_BOARD.GENERAL_MAINTENANCE.TASK_STATUS',
          [TableFieldProps.TEMPLATE_TYPE]: TemplateType.STATUS,
          [TableFieldProps.STATUS_MAP]: TaskBoardConstant.taskStatusConfig,
          [TableFieldProps.WIDTH]: ColumnSize.MEDIUM,
          [TableFieldProps.IS_FILTER]: true
        },
        {
          [TableFieldProps.FIELD]: GenericTableColumnProps.ACTIONS,
          [TableFieldProps.HEADER]: 'TABLE.FIELDS.ACTIONS',
          [TableFieldProps.TEMPLATE_TYPE]: TemplateType.COLUMN_TEMPLATE,
          [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL
        }
    ];

    static readonly actionConfig = {
        [TbGeneralMaintenanceAction.MODIFY]: {
          label: 'ACTIONS.MODIFY',
          icon: NextIcon.EDIT
        },
        [TbGeneralMaintenanceAction.DELETE]: {
          label: 'ACTIONS.DELETE',
          icon: NextIcon.DELETE
        }
    };

    static readonly extraFilterInitialValues: GenericObject = {};

    static readonly filterToggleConfig: FilterToggleControlConfig[] = [
        {
          [FilterToggleProps.CONTROL_KEY]: TaskBoardStatus.WAITING_APPROVAL,
          [FilterToggleProps.LABEL_KEY]: 'TASK_BOARD.STATUS.WAITING_APPROVAL',
          [FilterToggleProps.COLOR_TYPE]: ColorType.CAUTION
        },
        {
          [FilterToggleProps.CONTROL_KEY]: TaskBoardStatus.REJECTED,
          [FilterToggleProps.LABEL_KEY]: 'TASK_BOARD.STATUS.REJECTED',
          [FilterToggleProps.COLOR_TYPE]: ColorType.DANGER
        }
    ];
}