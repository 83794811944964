@if (selection && selection.length) {
  <bulk-actions-ribbon
    [actionLabelKey]="'VEHICLE_ORDER.ACTIONS.MODIFY_VEHICLE_ORDER'"
    [isTaskboard]="true"
    (actionSubmit)="modifyAction.emit($event)"
    (actionCancel)="cancelAction.emit($event)"
    (actionApprove)="approveAction.emit($event)"
  ></bulk-actions-ribbon>
} @else {
  <next-table-filter
    [toggleConfig]="TbVehicleOrderConstant.filterToggleConfig"
    [toggleTargetColumn]="TbVehicleOrderProps.TASK_STATUS"
    [extraFormGroup]="extraFormGroup"
    [extraFormGroupInitialValues]="TbVehicleOrderConstant.extraFilterInitialValues"
    [filterValues]="filterValues"
    (filterChange)="filterChange.emit($event)"
    [searchFilter]="false"
    [hasExtraFilter]="hasExtraFilter"
  >
    <div extraFilterContent class="tb-vehicle-order-filter__form">
      <input-container
        *ngIf="extraFormGroup | deepControl: [TbVehicleOrderFilterProps.DISTRIBUTOR_CODE_NAME] as distributorControl"
        [control]="distributorControl"
        [labelKey]="'TASK_BOARD.VEHICLE_ORDER.DISTRIBUTOR_CODE_NAME'"
        class="tb-vehicle-order-filter__item"
      >
        <next-dropdown
          [control]="distributorControl"
          [items]="distributorDropdown"
          [placeholder]="'TASK_BOARD.VEHICLE_ORDER.FILTER.DISTRIBUTOR'"
          [name]="TbVehicleOrderFilterProps.DISTRIBUTOR_CODE_NAME"
          [testIdDataAttr]="TbVehicleOrderFilterProps.DISTRIBUTOR_CODE_NAME"
          inputElement
        ></next-dropdown>
      </input-container>
      <input-container [labelKey]="'TASK_BOARD.VEHICLE_ORDER.ORDER_DATE'" class="tb-vehicle-order-filter__item">
        <date-from-to
          [fromControl]="extraFormGroup | deepControl: [TbVehicleOrderFilterProps.ORDER_DATE_FROM]"
          [toControl]="extraFormGroup | deepControl: [TbVehicleOrderFilterProps.ORDER_DATE_TO]"
          [testIdDataAttr]="TbVehicleOrderProps.ORDER_DATE"
          inputElement
        ></date-from-to>
      </input-container>
      <input-container
        *ngIf="extraFormGroup | deepControl: [TbVehicleOrderFilterProps.SOURCE_LOCATION] as sourceLocationControl"
        [control]="sourceLocationControl"
        [labelKey]="'TASK_BOARD.VEHICLE_ORDER.SOURCE_LOCATION'"
        class="tb-vehicle-order-filter__item"
      >
        <next-dropdown
          [control]="sourceLocationControl"
          [items]="sourceLocationDropdown"
          [placeholder]="'TASK_BOARD.VEHICLE_ORDER.FILTER.SOURCE_LOCATION'"
          [name]="TbVehicleOrderFilterProps.SOURCE_LOCATION"
          [testIdDataAttr]="TbVehicleOrderFilterProps.SOURCE_LOCATION"
          inputElement
        ></next-dropdown>
      </input-container>
      <input-container
        *ngIf="extraFormGroup | deepControl: [TbVehicleOrderFilterProps.VEHICLE_MODEL] as vehicleModelControl"
        [control]="vehicleModelControl"
        [labelKey]="'TASK_BOARD.VEHICLE_ORDER.VEHICLE_MODEL'"
        class="tb-vehicle-order-filter__item"
      >
        <next-input
          [control]="vehicleModelControl"
          [name]="TbVehicleOrderFilterProps.VEHICLE_MODEL"
          [placeholder]="'TASK_BOARD.VEHICLE_ORDER.FILTER.VEHICLE_MODEL' | translate"
          [testIdDataAttr]="TbVehicleOrderFilterProps.VEHICLE_MODEL"
          inputElement
        ></next-input>
      </input-container>
      <input-container
        *ngIf="extraFormGroup | deepControl: [TbVehicleOrderFilterProps.ORDER_NUMBER] as orderNumberControl"
        [control]="orderNumberControl"
        [labelKey]="'TASK_BOARD.VEHICLE_ORDER.ORDER_NUMBER'"
        class="tb-vehicle-order-filter__item"
      >
        <next-dropdown
          [control]="orderNumberControl"
          [items]="orderNumberDropdown"
          [placeholder]="'TASK_BOARD.VEHICLE_ORDER.FILTER.ORDER_NUMBER'"
          [name]="TbVehicleOrderFilterProps.ORDER_NUMBER"
          [testIdDataAttr]="TbVehicleOrderFilterProps.ORDER_NUMBER"
          inputElement
        ></next-dropdown>
      </input-container>
    </div>
    <div extraFilterContent class="tb-vehicle-order-filter__form">
      <input-container
        *ngIf="extraFormGroup | deepControl: [TbVehicleOrderFilterProps.ACCOUNT_STATUS] as accountStatusControl"
        [control]="accountStatusControl"
        [labelKey]="'TASK_BOARD.VEHICLE_ORDER.ACCOUNT_STATUS'"
        class="tb-vehicle-order-filter__item"
      >
        <next-dropdown
          [control]="accountStatusControl"
          [items]="accountStatusDropdown"
          [placeholder]="'TASK_BOARD.VEHICLE_ORDER.FILTER.ACCOUNT_STATUS'"
          [name]="TbVehicleOrderFilterProps.ACCOUNT_STATUS"
          [testIdDataAttr]="TbVehicleOrderFilterProps.ACCOUNT_STATUS"
          inputElement
        ></next-dropdown>
      </input-container>
      <input-container
        *ngIf="extraFormGroup | deepControl: [TbVehicleOrderFilterProps.OPPER_STATUS] as opperStatusControl"
        [control]="opperStatusControl"
        [labelKey]="'TASK_BOARD.VEHICLE_ORDER.OPER_STATUS'"
        class="tb-vehicle-order-filter__item"
      >
        <next-dropdown
          [control]="opperStatusControl"
          [items]="operationalStatusDropdown"
          [placeholder]="'TASK_BOARD.VEHICLE_ORDER.FILTER.OPERATIONAL_STATUS'"
          [name]="TbVehicleOrderFilterProps.OPPER_STATUS"
          [testIdDataAttr]="TbVehicleOrderFilterProps.OPPER_STATUS"
          inputElement
        ></next-dropdown>
      </input-container>
      <input-container
        *ngIf="extraFormGroup | deepControl: [TbVehicleOrderFilterProps.RISK_CLASS] as riskClassControl"
        [control]="riskClassControl"
        [labelKey]="'TASK_BOARD.VEHICLE_ORDER.RISK_CLASSIFICATION'"
        class="tb-vehicle-order-filter__item"
      >
        <next-dropdown
          [control]="riskClassControl"
          [items]="riskClassDrpdown"
          [placeholder]="'TASK_BOARD.VEHICLE_ORDER.FILTER.RISK_CLASSIFICATION'"
          [name]="TbVehicleOrderFilterProps.RISK_CLASS"
          [testIdDataAttr]="TbVehicleOrderFilterProps.RISK_CLASS"
          inputElement
        ></next-dropdown>
      </input-container>
    </div>
  </next-table-filter>
}
