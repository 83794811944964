<tb-general-maintenance-filter 
    [filterValues]="filterParams"
    (filterChange)="handleFilterChange($event)"
    [hasExtraFilter]="hasExtraFilter"></tb-general-maintenance-filter>
    
<next-table
  *ngIf="tableList"
  [dataKey]="TbGeneralMaintenanceProps.ID"
  [data]="tableList"
  [columns]="TbGeneralMaintenanceConstant.tableListConfig"
  [filterValues]="filterParams"
  [sortField]="sortField"
  [sortOrder]="-1"
  (pageChange)="handlePaginationChange($event)"
  [selectedRows]="selectedCountry"
  (sortPageChange)="sortPageChangeHandle($event)"
  [totalRecords]="tableList.length"
>
    <ng-template pTemplate="columnTemplateRef" let-data="data" let-columnConfig="columnConfig">
    <div *ngIf="columnConfig[TableFieldProps.FIELD] === GenericTableColumnProps.ACTIONS"
      class="tb-general-maintenance__column">
      <ng-container [ngSwitch]="data[TbGeneralMaintenanceProps.STATUS]">
        <ng-container *ngSwitchCase="TaskBoardStatus.WAITING_APPROVAL">
          <link-button
            [iconType]="NextIcon.EYE_OPENED"
            [iconColor]="IconColor.BRAND_PRIMARY"
            [iconSize]="IconSizeModifier.REGULAR"
            (clickEvt)="handleView(data)"
          ></link-button>
        </ng-container>
        <ng-container *ngSwitchCase="TaskBoardStatus.REJECTED">
          <options-menu [options]="data[GenericTableColumnProps.MENU_LIST]"></options-menu>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</next-table>

<country-edit-popup *ngIf="selectedCountry" [isOpen]="activePopup"
                        [selectedCountry]="selectedCountry"
                        [riskRatingDropdown]="countriesRiskRatingDropdown"
                        [titleKey]="('COUNTRY.FIELDS.EDIT_COUNTRY') | translate: {nameCountry: selectedCountry.name}"
                        (cancel)="dismissPopup()"
                        (submitCountry)="updateCountry($event)"></country-edit-popup>